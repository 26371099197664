import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import NavBar from "../components/basic/nav-bar"
import Seo from '../components/basic/seo'
import BackgroundImage from "gatsby-background-image"
import Homeselect from '../components/homeselect'

export default function Index() {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "home_background.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const imageData = data.desktop.childImageSharp.fluid
  return (
    <BackgroundImage
        Tag="section"
        className="container-fluid"
        fluid={imageData}
        style={{ height: '100vh', backgroundPosition: 'bottom' }}
      >
      <Seo title="home" />
      <NavBar />
      <Homeselect />
    </BackgroundImage>
  )
}