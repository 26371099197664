import React from 'react'
import { Link } from "gatsby"
import madrid from '../../static/images/thumbnail_madrid.jpg'
import barcelona from '../../static/images/thumbnail_barcelona.jpg'
import utopicus from '../../static/images/thumbnail_utopicus.jpg'

export default function Homeselect() {
  return (
    <div className="container">
    <div className="row d-flex align-items-center" style={{ height: '86vh' }}>
      <div className="col">
        <h1 className="text-hide text-light">
          <strong style={{fontWeight: '800'}}>COSBIM</strong> <small>360</small>
        </h1>
        <h3 className="text-light">La inmobiliaria refente en oficinas de calidad de la zona euro.</h3>
      </div>
      <div className="col">
        <Link to="/madrid" className="text-decoration-none">
          <div className="card bg-transparent" style={{ borderColor: 'transparent' }}>
            <img src={madrid} className="card-img-top" alt="Madrid" />
            <div className="card-body">
              <h3 className="text-center">
                <i className="text-light">Madrid</i>
                <i className="text-light bi bi-chevron-compact-right"></i>
              </h3>        
            </div>                
          </div>
        </Link>
      </div>
      <div className="col">
        <Link to="/barcelona" className="text-decoration-none">
          <div className="card bg-transparent" style={{ borderColor: 'transparent' }}>
            <img src={barcelona} className="card-img-top" alt="Barcelona" />
            <div className="card-body">
              <h3 className="text-center">
                <i className="text-light">Barcelona</i>
                <i className="text-light bi bi-chevron-compact-right"></i>
              </h3>
            </div>
          </div>
        </Link>
      </div>
      <div className="col">
        <Link to="/utopicus" className="text-decoration-none">
          <div className="card bg-transparent" style={{ borderColor: 'transparent' }}>
            <img src={utopicus} className="card-img-top" alt="Utopicus" />
            <div className="card-body">
              <h3 className="text-center">
                <i className="text-light">Utopicus</i>
                <i className="text-light bi bi-chevron-compact-right"></i>
              </h3>
            </div>
          </div>
        </Link>
      </div>
    </div>
  </div>
  )
}
